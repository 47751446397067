.register_container {
  height: 100%;
  color: #fff;
  padding: 5rem 0;
  width: 100%;
  background: radial-gradient(
      40px circle at 150px 140px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 102%,
      transparent 77%
    ),
    radial-gradient(
      75px circle at 300px 400px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 100%,
      transparent 51%
    ),
    radial-gradient(
      30px circle at 400px 20px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 102%,
      transparent 51%
    ),
    radial-gradient(
      50px circle at 700px 100px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 100%,
      transparent 51%
    ),
    radial-gradient(
      40px circle at 500px 50px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 100%,
      transparent 51%
    ),
    radial-gradient(
      25px circle at 00px 250px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 103%,
      transparent 51%
    ),
    radial-gradient(
      80px circle at 650px 500px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 100%,
      transparent 51%
    ),
    radial-gradient(
      30px circle at 150px 300px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 103%,
      transparent 50.4%
    ),
    radial-gradient(
      10px circle at 50px 420px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 100%,
      transparent 51%
    ),
    radial-gradient(
      50px circle at 100px 550px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 100%,
      transparent 51%
    ),
    radial-gradient(
      45px circle at 300px 800px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 100%,
      transparent 51%
    ),
    radial-gradient(
      100px circle at 700px 1200px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 100%,
      transparent 51%
    ),
    radial-gradient(
      40px circle at 30px 1050px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 102%,
      transparent 51%
    ),
    radial-gradient(
      90px circle at 300px 1100px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 100%,
      transparent 51%
    ),
    radial-gradient(
      25px circle at 90px 850px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 105%,
      transparent 51%
    ),
    radial-gradient(
      60px circle at 900px 800px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 100%,
      transparent 50.4%
    ),
    radial-gradient(
      10px circle at 50px 720px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 110%,
      transparent 51%
    ),
    linear-gradient(to right, transparent 50%, rgb(255, 255, 255) 50%);
  background-color: #283046;
}

.register_form {
  background-color: #fff;
  width: 70%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
}

@media (max-width: 575.98px) {
  .register_form {
    width: 90%;
  }
}

.register_form_title {
  font-family: "Red Hat Display", sans-serif !important;
  font-weight: 700 !important;
  font-size: 26px !important;
  font-style: normal !important;
  line-height: 35px;
  color: #363636 !important;
}

.have_an_account {
  font-family: "Red Hat Display", sans-serif !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  font-style: normal !important;
  line-height: 24px;

  color: #363636;
}

.register_link {
  font-family: "Red Hat Display", sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  font-style: normal !important;

  color: #5b5b5b;
}

.company_name_label {
  font-family: "Inter", sans-serif !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  font-style: normal !important;
  line-height: 24px;
  color: #464646;
}

.register_btn {
  background-color: #1273eb;
  color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 22px;
  width: 40%;
}

.register_btn:hover {
  color: #fff;
}

.password_icon {
  right: 0.8rem;
  top: 25%;
}
.register_form_tag label {
  color: #464646;

  font-family: "Inter", sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  font-style: normal !important;
  line-height: 24px;
}

input[name="sendEmail"],
input[name="sendEmail"]:checked,
input[name="sendEmail"]:hover,
input[name="sendEmail"]:focus {
  width: 1rem;
  height: 1rem;
  accent-color: #c4c4c4;
}
@media (max-width: 1510px) {
  .register_container {
    background: radial-gradient(
        50px circle at 100px 150px,
        #283046,
        #283046 98%,
        #ffffff52 100%,
        #283046 102%,
        transparent 77%
      ),
      radial-gradient(
        75px circle at 100px 400px,
        #283046,
        #283046 98%,
        #ffffff52 100%,
        #283046 100%,
        transparent 51%
      ),
      radial-gradient(
        25px circle at 10px 250px,
        #283046,
        #283046 98%,
        #ffffff52 100%,
        #283046 103%,
        transparent 51%
      ),
      radial-gradient(
        10px circle at 50px 450px,
        #283046,
        #283046 98%,
        #ffffff52 100%,
        #283046 100%,
        transparent 51%
      ),
      radial-gradient(
        80px circle at 50px 900px,
        #283046,
        #283046 98%,
        #ffffff52 100%,
        #283046 100%,
        transparent 51%
      ),
      radial-gradient(
        50px circle at 100px 550px,
        #283046,
        #283046 98%,
        #ffffff52 100%,
        #283046 100%,
        transparent 51%
      ),
      linear-gradient(to right, transparent 50%, rgb(255, 255, 255) 50%);
    background-color: #283046;
  }
}


.register_page_title {
  font-size: 1.5rem;
  font-weight: 600;
  padding-left: 5px;
}