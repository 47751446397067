.sidebar {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 250px;
  background-color: #283046;
  height: 150vh;
  overflow-y: auto;
  transition: width .3s ease-in !important;
}

.sidebarclose {
  display: block;
  position: fixed;
  width: 60px;
  top: 0;
  bottom: 0;
  background-color: #283046;
  height: 150vh;
  overflow-y: auto;
  transition: width .3s ease-in !important;
}

.sidebarclose>.sidebar-item {
  width: 50px;
  margin: auto;
  display: block;
}

.sidebarclose>.open>.sidebar-title {
  background-color: #161D31;
  border-radius: 5px;
  width: 50px;
}

.sidebarclose>.open>.sidebar-title .toggle-btn {
  display: none;
}

.sidebarclose>.sidebar-item>.sidebar-title .toggle-btn {
  display: none;
}


.sidebar-item {
  padding: .75em 1em;
  display: block;
  transition: background-color .15s;
  border-radius: 5px;
  width: 230px;
  margin: auto;
}

/* .sidebar-item:hover{
  background-color: rgba(255, 255, 255, .1);
} */

.sidebar-title {
  display: flex;
  color: #AAAAAA;
  justify-content: space-between;
}

.sidebar-title span i {
  display: inline-block;
  width: 1.5em;
}

.sidebar-title .toggle-btn {
  cursor: pointer;
  transition: transform .3s;
}

.open>.sidebar-title .toggle-btn {
  transform: rotate(180deg);
}

.open>.sidebar-title {
  background-color: #161D31;
  border-radius: 5px;
  width: 230px;
  margin: auto;
  padding: .75em 1em;

}

.sidebar-content {
  padding-top: .25em;
  height: 0;
  overflow: hidden;
}

.open>.sidebar-content {
  height: auto;
}

.sidebar-item.plain {
  color: #AAAAAA;
  text-decoration: none;
}

/* .sidebar-item.plain:hover{
  text-decoration: underline;
} */
.sidebar-item.plain i {
  display: inline-block;
  width: 1.7em;
}

.active {
  background-color: #161D31;
  border-radius: 5px;
  color: #fff !important;
  width: 230px;
  margin: auto;
}

.childactive {
  color: #fff !important;
  margin: auto;
}

.sidebarclose-active {
  width: 60px;
  z-index: 999;
  transition: width .3s ease-in !important
}

.sidebar .toggle_button {
  position: absolute;
  top:50px;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
}

.sidebarclose-active .toggle_button {
  left: 0px;
  top: 9px;
  z-index: 999;
  position: absolute;
  cursor: pointer;
}

.sidebarclose-active .open {
  display: none;
}

.sidebarclose-active .sidebar-item {
  display: none;
}

@media(max-width:787px) {
  .sidebar {
    z-index: 999;
  }

  .sidebarclose {
    z-index: 999;
  }

}