.authlogo_container {
  position: absolute;
  top: 2rem;
  left: 2.5rem;
}

.authlogo_image {
  width: 163.29px;
  height: 32.19px;
}

.authlogo_text {
  font-family: "Red Hat Display", sans-serif !important;
  font-weight: 500 !important;
  letter-spacing: 1px;
  margin-top: 5px;
  font-style: normal !important;
  line-height: 24px;
}