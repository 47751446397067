.page_heading {
  color: #e3001b;
}

.jira_from_to_date {
  border: 1px solid #e5e5e5;
  border-radius: 0px;
  background-color: #fff !important;
  color: #e3001b;
}

.jira_filter_container {
  margin-top: 1rem;
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.jira_pagination_container {
  margin-top: 1rem;
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.jira_radio_form {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.jira_radio_group {
  accent-color: #383838;
}

.jira_checkbox_group {
  accent-color: #e3001b;
}

.jira_pagination_item {
  display: flex;
  gap: 1rem;
  height: auto;
  align-items: center;
  background-color: #f5f5f5;
  padding: 5px 10px;
}

.jira_pagination_subitem {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.pagination_icon {
  display: flex;
  gap: 1rem;
}

.jira_issue_table_container {
  width: 100% !important;
  table-layout: auto !important;
}

.jira_issue_table_header th {
  border: none;
  vertical-align: middle !important;
  white-space: pre-wrap;
  background-color: #f5f5f5;
}
.jira_issue_table_container tbody tr td {
  white-space: pre-wrap;
}

.jira_issue_table_header tr {
  border: 1px solid #e5e5e5;
}

.jira_issue_table_container .issue_table_column {
  width: 110px;
  max-width: 110px;
  min-width: 100px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.jira_issue_table_container .title_table_column {
  width: 85px;
  max-width: 85px;
  min-width: 85px;
  white-space: pre-wrap;
}

.jira_issue_table_container .type_table_column {
  font-family: "Montserrat", sans-serif !important;
}

.jira_issue_table_container .type_table_column {
  width: 110px;
  max-width: 110px;
  min-width: 110px;
  white-space: pre-wrap;
}

.jira_issue_table_container .status_table_column {
  width: 200px;
  max-width: 300px;
  min-width: 170px;
  white-space: pre-wrap;
}

.jira_issue_table_container .created_table_column {
  width: 90px;
  max-width: 90px;
  min-width: 90px;
  white-space: pre-wrap;
}

.jira_issue_table_container .cert_table_column {
  width: 90px;
  max-width: 90px;
  white-space: pre-wrap;
}

.jira_issue_table_container .first_response_table_column {
  width: 120px;
  max-width: 150px;
  min-width: 100px;
  white-space: pre-wrap;
}

.jira_issue_table_container .sla_response_table_column {
  width: 100px;
  max-width: 100px;
  min-width: 100px;
  white-space: pre-wrap;
}

.jira_issue_table_container .sla_response_max_table_column {
  width: 120px;
  max-width: 120px;
  white-space: pre-wrap;
}

.jira_issue_table_container .sla_wa_max_table_column {
  width: 20px;
  max-width: 20px;
  white-space: pre-wrap;
}

.jira_issue_table_container .sla_resolution_table_column {
  width: 95px;
  max-width: 95px;
  white-space: pre-wrap;
}

.jira_issue_table_container .sla_resolution_max_table_column {
  width: 140px;
  max-width: 140px;
  white-space: pre-wrap;
}

.jira_issue_table_container .sla_updatetime_table_column {
  width: 90px;
  max-width: 90px;
  white-space: pre-wrap;
}

.pagination_icons {
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
}

.collapsed-sidebar {
  transition: 0.3s;
}
.employment_view_full_report {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #1273eb;
}

.total_count_data_text {
  color: #363636;
}

.total_change_data_text {
  font-family: "Montserrat", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;

  color: #363636;
}

.card_footer_text {

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;

  color: #363636;
}
.change_score {
  font-weight: 700;
  font-size: 21px;
  line-height: 37px;
}