.add_survey_startdate_label {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #747474;
  white-space: nowrap;
}

.add_survey_startdate_value {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #747474;
}
.bg-progressbar{
  background-color: #FF9F43;
}
