.Agency_text {
    color: #767A7E;
    font-style: normal;
    font-weight: 600;
    line-height: 1.125rem;
    font-family: Red Hat Display;
}

.Agency_text_2 {
    color: #000;
    font-weight: 400;
    font-family: Red Hat Display;
}

.font_weight_700_title {
    font-weight: 700;
    font-family: Red Hat Display;
    color: #5C5F63;
}