.profile_current_plan_alert {
  background: #FFF5EC;
  color: #E8913D;
  font-weight: 600;
  border-radius: 4px;
  width: 100%;
  padding: 5px 0;
}

.profile_current_plan_alert_child {
  background: linear-gradient(180deg, #FFE9D6 0%, rgba(255, 245, 236, 0) 100%);
  width: 100%;
  font-weight: normal;
  padding: 5px 0;
}

.billing_history_table_container {
  width: 100% !important;
  table-layout: auto !important;
}

.billing_history_table_header th {
  border: none;
  vertical-align: middle !important;
  white-space: pre-wrap;
  background-color: #f5f5f5;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #5B5B5B;
}

.billing_history_table_container tbody tr td {
  white-space: pre-wrap;
}

.billing_history_table_header tr {
  border: 1px solid #e5e5e5;
}

.billing_history_table_container .billing_history_id_table_column {
  width: 50px;
  max-width: 50px;
  min-width: 30px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.billing_history_table_container .billing_history_transid_table_column {
  width: 200px;
  max-width: 200px;
  min-width: 200px;
  white-space: pre-wrap;
}

.billing_history_table_container .billing_history_transdate_table_column {
  font-family: "Montserrat", sans-serif !important;
}

.billing_history_table_container .billing_history_transdate_table_column {
  width: 110px;
  max-width: 110px;
  min-width: 110px;
  white-space: pre-wrap;
}

.billing_history_table_container .billing_history_playtype_table_column {
  width: 100px;
  max-width: 100px;
  min-width: 100px;
  white-space: pre-wrap;
}

.billing_history_table_container .billing_history_planstdate_table_column {
  width: 100px;
  max-width: 100px;
  min-width: 100px;
  white-space: pre-wrap;
}

.billing_history_table_container .billing_history_amount_table_column {
  width: 80px;
  max-width: 80px;
  min-width: 80px;
  white-space: pre-wrap;
}

.billing_history_table_container .billing_history_planeddate_table_column {
  width: 110px;
  max-width: 110px;
  min-width: 110px;
  white-space: pre-wrap;
}
.Current_plan_header{
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #363636;
}