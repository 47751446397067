.ScoreUp {
  color: #28c76f;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}
.ScoreDown {
  color: #ea5455;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.iconUp {
  color: #28c76f;
}

.iconDown {
  color: #ea5455;
}
.ChangeInScores{
  font-family: 'Red Hat Display', 'sans-serif';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  color: #161D31;
}
.employment_view_full_report {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #1273eb;
}
.employment_view_full_report_disabled {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #c4c4c4;
}

@media (max-width: 991.98px) {
  .government_change_in_assessment_row {
    gap: 1rem;
  }
}
