.pricing_plan_page_title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  text-align: center;

  color: #464646;
}

.pricing_page_description {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #363636;
}

.plan_price_doller {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 49px;
  line-height: 60px;
  text-align: center;
  color: #464646;
}

.plan_price_doller::before {
  content: "$";
  font-size: small;
  position: absolute;
  top: 5px;
  left: -10px;
}

.post_error_loading {
  padding-top: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
