* {
    font-family: 'Montserrat';
}

.pricing_plan_page_title {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    text-align: center;

    color: #464646;
}

.pricing_plan_page_description {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #5b5b5b;
}

.plan_price_doller {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 49px;
    line-height: 60px;

    text-align: center;

    color: #464646;
}

.plan_price_doller::before {
    content: "$";
    font-size: small;
    position: absolute;
    top: 5px;
    left: -10px;
}
.ribbon {
    width: 120px;
    height: 120px;
    overflow: hidden;
    position: absolute;
  }
  .ribbon::before,
  .ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #2980b9;
  }
  .ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color: #28C76F;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #fff;
    font: 400 12px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;
  }
  
  /* top left*/
  .ribbon-top-left {
    top: 0px;
    left: 0px;
  }
  .ribbon-top-left::before,
  .ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
  }
  .ribbon-top-left::before {
    top: 0;
    right: 0;
  }
  .ribbon-top-left::after {
    bottom: 0;
    left: 0;
  }
  .ribbon-top-left span {
    right: -34px;
    top: 30px;
    transform: rotate(-45deg);
  }
  