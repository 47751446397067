.thumb {
	margin-bottom: 15px;
  cursor: pointer;
}
.thumb:last-child {
	margin-bottom: 0;
}
/* CSS Image Hover Effects: https://www.nxworld.net/tips/css-image-hover-effects.html */
.thumb 
figure img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}
.thumb 
figure:hover img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

.img-thumbnail{
  padding: 2.25rem;
    background-color: #F2F2F2;
    border: none;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;
}

.file-name {
  /* width: calc(100% - 26rem); */
  min-height: 1rem;
  font-weight: 500;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file-manager-item .form-check {
  position: absolute;
  top: 0.5rem;
  left: 0.5em;
}

.largerCheckbox{
  transform: scale(1.4);
}

.view-container .file-manager-item .form-check-input:not(:checked) {
  background-color: transparent;
}


.view-container.list-view .file-manager-item .form-check {
  margin: 0 0.4rem 0 1.75rem;
}

