.governmentlistofsurvey_table_container {
  width: 100% !important;
  table-layout: auto !important;
}

.governmentlistofsurvey_table_header th {
  border: none !important;
  vertical-align: middle !important;
  white-space: pre-wrap;
  background-color: #f5f5f5;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #5b5b5b;
}

.governmentlistofsurvey_table_container tbody tr td {
  white-space: pre-wrap;
}
.governmentlistofsurvey_table_header .Survey_number_table_column {
  width: 180px;
  max-width: 180px;
  min-width: 180px;
}
.governmentlistofsurvey_table_header .Survey_Date_table_column {
  width: 180px;
  max-width: 180px;
  min-width: 180px;
}
.governmentlistofsurvey_table_header .Action_table_column {
  width: 240px;
  max-width: 240px;
  min-width: 240px;
}
.btn-outline-continue {
  color: #ff9f43;
  border-color: #ff9f43;
}
.post_loading {
  padding-top: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1440px) {
  .governmentlistofsurvey_table_header .Survey_Progress_table_column {
    width: 400px;
    max-width: 400px;
    min-width: 400px;
  }
}
