.bg_firm {
  height: 100vh;
  width: 100%;
  background: linear-gradient(to right, transparent 80%, #0069d9 40%) no-repeat;
}
.firm_login_container {
  padding: 5rem 0;
  font-family: "Montserrat" !important;
}

.firm_register_btn {
  width: 100% !important;
  color: #363636 !important;
  background: #fff !important;
  font-family: "Montserrat" !important;
  font-size: 1rem !important;
  height: 3.4rem;
  margin: 2rem;
}

.login_btn {
  height: 3.4rem;
}

.password_icon {
  right: 0.8rem;
  top: 25%;
}

.firm_form_details p {
  padding: 0 2rem !important;
}

.firm_form_details {
  padding: 4rem 0;
}

@media (max-width: 1330px) {
  .firm_form_details {
    height: 85vh !important;
  }
}

@media (max-width: 1200px) {
  .bg_firm {
    background: #fff;
  }
  .firm_login_form {
  }
  .firm_form_details {
    padding: 0;
  }
}
@media (max-width: 1173px) {
  .firm_form_title {
    margin: 0.5rem;
  }
  .firm_form_details {
    height: 70vh !important;
  }
}

@media (max-width: 570px) {
  .firm_form_details h2 {
    font-size: 1.7rem;
  }
  .firm_form_details p {
    font-size: 0.8rem;
  }
  .login_btn {
    height: 2.4rem;
  }
  .firm_register_btn {
    height: 2.4rem;
  }
}
@media (max-width: 400px) {
  .firm_form_details h2 {
    font-size: 1.5rem;
  }
  .firm_form_details p {
    font-size: 0.7rem;
  }
  .firm_register_btn {
    height: 2.3rem;
  }
  .firm_form_details {
    height: 80vh !important;
  }
  .login_btn {
    height: 2.3rem;
  }
}

.existing_user_text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;

  color: #464646;
}

.firm_login_label_text {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 28px;
  font-feature-settings: "liga" off;
  color: #464646;
}

.firm_login_input:focus {
  border-bottom: 3px solid #1273eb;
  box-shadow: none;
}

.login_page_title{
  font-weight: 700;
}