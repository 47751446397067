.filter_container {
  background-color: #f2f2f280;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
}

.dotted {
  border: 1px;
  width: 100%;
  margin: 0;
  padding: 0;
  border-color: #c5c5c5;
  border-style: none none dashed;
}

.bid_listing_card_container {
  border-radius: 0.5rem;
  overflow: hidden;
  height: 100%;
}

.bid_listing_card_container:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.bid_listing_card_header {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-bottom: none;
  background-color: #e7f1fd;
}

.pageSize {
  border: none !important;
  background-color: #f2f2f2 !important;
}

.sort_by {
  white-space: nowrap;
  font-weight: 500;
}

.font_weight {
  font-weight: 600;
}