.login_container {
  height: 100vh;
  color: #fff;
  padding: 5rem 0;
  width: 50%;
  background: radial-gradient(
      50px circle at 100px 150px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 102%,
      transparent 77%
    ),
    radial-gradient(
      75px circle at 300px 400px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 100%,
      transparent 51%
    ),
    radial-gradient(
      30px circle at 400px 20px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 102%,
      transparent 51%
    ),
    radial-gradient(
      50px circle at 700px 100px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 100%,
      transparent 51%
    ),
    radial-gradient(
      40px circle at 500px 250px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 100%,
      transparent 51%
    ),
    radial-gradient(
      25px circle at 00px 250px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 103%,
      transparent 51%
    ),
    radial-gradient(
      80px circle at 650px 500px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 100%,
      transparent 51%
    ),
    radial-gradient(
      30px circle at 250px 200px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 103%,
      transparent 50.4%
    ),
    radial-gradient(
      10px circle at 50px 420px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 100%,
      transparent 51%
    ),
    radial-gradient(
      50px circle at 100px 550px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 100%,
      transparent 51%
    ),
    radial-gradient(
      45px circle at 300px 800px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 100%,
      transparent 51%
    ),
    radial-gradient(
      10px circle at 400px 600px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 120%,
      transparent 51%
    ),
    radial-gradient(
      40px circle at 700px 770px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 102%,
      transparent 51%
    ),
    radial-gradient(
      90px circle at 500px 850px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 100%,
      transparent 51%
    ),
    radial-gradient(
      25px circle at 90px 850px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 105%,
      transparent 51%
    ),
    radial-gradient(
      60px circle at 900px 800px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 100%,
      transparent 50.4%
    ),
    radial-gradient(
      10px circle at 50px 720px,
      #283046,
      #283046 98%,
      #ffffff52 100%,
      #283046 110%,
      transparent 51%
    );
  background-color: #283046;
}

.login_form {
  background-color: #fff;
  width: 40%;
  max-width: 539px;
  background: #ffffff;
  box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

@media (max-width: 820px) {
  .login_form {
    width: 70%;
  }
}
@media (max-width: 575.98px) {
  .login_form {
    width: 90%;
  }
}

.login_form_title {
  font-family: "Red Hat Display", sans-serif !important;
  font-weight: 700 !important;
  font-size: 52px !important;
  font-style: normal !important;
  line-height: 69px;
  color: #363636 !important;
}

.login_link {
  font-family: "Red Hat Display", sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  font-style: normal !important;

  color: #5b5b5b;
}

.login_btn {
  background-color: #1273eb;
  color: #fff;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 22px;
  width: 100%;
}

.login_btn:hover {
  color: #fff;
}

.password_icon {
  right: 0.8rem;
  top: 25%;
}
.sign_up_form label {
  color: #464646;
  font-family: "Inter", sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  font-style: normal !important;
  line-height: 24px;
}

.login_welcome_text {
  font-family: "Red Hat Display", sans-serif !important;
  font-weight: 400 !important;
  font-size: 22px !important;
  font-style: normal !important;
  line-height: 20px;
  color: #363636;
}

input[name="remember"],
input[name="remember"]:checked,
input[name="remember"]:hover,
input[name="remember"]:focus {
  width: 1rem;
  height: 1rem;
  accent-color: #c4c4c4;
}
