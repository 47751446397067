.Headers .h4 {
  font-family: "Montserrat" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #464646;
}

.CardTitle {
  font-family: "Montserrat" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  left: 24px;
  color: #484a4c;
}

.reports_table_container {
  width: 100% !important;
  table-layout: auto !important;
}

.reports_table_container::-webkit-scrollbar {
  width: 1rem;
}

.reports_table_container::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.reports_table_container::-webkit-scrollbar-thumb {
  background-color: #d3d2d0;
  border-radius: 100px;
}

.reports_table_header th {
  vertical-align: middle !important;
  white-space: pre-wrap;
  background-color: #f5f5f5;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #5b5b5b;
  /* border: none; */
}

.reports_table_container tbody tr td {
  white-space: pre-wrap;
}

.reports_table_header tr {
  border: 1px solid #e5e5e5;
}

.reports_table_header .number_table_column {
  width: 60px;
  max-width: 60px;
  min-width: 50px;
  white-space: pre-wrap;
}
.reports_table_header .firmname_table_column {
  width: 110px;
  max-width: 110px;
  min-width: 100px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.reports_table_header .date_table_column {
  width: 130px;
  max-width: 130px;
  min-width: 120px;
  white-space: pre-wrap;
}
.reports_table_header .score_table_column {
  width: 80px;
  max-width: 80px;
  min-width: 80px;
  white-space: pre-wrap;
}
.reports_table_header .employ_table_column {
  width: 100px;
  max-width: 100px;
  min-width: 90px;
  white-space: pre-wrap;
}
.reports_table_header .revenues_table_column {
  width: 110px;
  max-width: 110px;
  min-width: 100px;
  white-space: pre-wrap;
}
.reports_table_header .sales_table_column {
  width: 80px;
  max-width: 80px;
  min-width: 70px;
  white-space: pre-wrap;
}
.reports_table_header .credit_score_table_column {
  width: 80px;
  max-width: 80px;
  min-width: 80px;
  white-space: pre-wrap;
}
.reports_table_header .new_employees_table_column {
  width: 120px;
  max-width: 120px;
  min-width: 120px;
  white-space: pre-wrap;
}
.reports_table_header .change_revenues_table_column {
  width: 110px;
  max-width: 110px;
  min-width: 110px;
  white-space: pre-wrap;
}
.reports_table_header .change_sales_table_column {
  width: 120px;
  max-width: 120px;
  min-width: 120px;
  white-space: pre-wrap;
}
.reports_table_header .subcategory_score_table_column {
  width: 110px;
  max-width: 110px;
  min-width: 100px;
  white-space: pre-wrap;
}
