.sidebar-file-manager {
  width: 260px;
  height: 100%;
  background-color: #fff;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  transition: all 0.3s ease, background 0s;
}

.sidebar-file-manager .sidebar-inner {
  height: inherit;
}

.sidebar-file-manager .sidebar-inner .dropdown-actions {
  width: 100%;
  padding: 1.5rem 1.5rem 1.8rem;
}

.sidebar-file-manager .sidebar-inner .sidebar-close-icon {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  font-size: 1.5rem;
  z-index: 5;
  cursor: pointer;
}

.sidebar-file-manager .sidebar-inner .add-file-btn ~ .dropdown-menu {
  width: 85%;
}

.sidebar-file-manager .sidebar-inner .add-file-btn ~ .dropdown-menu:before {
  display: none;
}

.sidebar-file-manager .sidebar-inner .sidebar-list {
  position: relative;
  height: calc(100% - 85px);
}

.sidebar-file-manager .sidebar-inner .sidebar-list .my-drive {
  padding: 0.58rem 0;
}

.sidebar-file-manager .sidebar-inner .sidebar-list .my-drive .jstree-node .jstree-icon {
  background-position: 0.5rem;
  background-size: 1.3rem;
}

.sidebar-file-manager
  .sidebar-inner
  .sidebar-list
  .my-drive
  .jstree-node
  .jstree-anchor
  > .jstree-themeicon {
  margin-right: 0.5rem;
}

.sidebar-file-manager
  .sidebar-inner
  .sidebar-list
  .my-drive
  .jstree-node.jstree-closed
  > .jstree-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%235e5873' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
}

.sidebar-file-manager
  .sidebar-inner
  .sidebar-list
  .my-drive
  .jstree-node.jstree-open
  > .jstree-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%235e5873' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}

.sidebar-file-manager .sidebar-inner .sidebar-list .list-group-labels,
.sidebar-file-manager .sidebar-inner .sidebar-list .storage-status {
  margin-top: 2.2rem;
}

.sidebar-file-manager .sidebar-inner .sidebar-list .list-group-item {
  padding: 0.58rem 1.9rem;
  font-weight: 500;
  border: 0;
  border-radius: 0;
  border-left: 2px solid transparent;
}

.sidebar-file-manager .sidebar-inner .sidebar-list .list-group-item:hover {
  z-index: 0 !important;
}

.sidebar-file-manager .sidebar-inner .sidebar-list .list-group-item + .list-group-item.active {
  margin-top: 0;
}

.sidebar-file-manager .sidebar-inner .sidebar-list .active {
  background-color: transparent;
  border-color: #7367f0;
  color: #7367f0;
}

.sidebar-file-manager.show {
  transition: all 0.25s ease, background 0s !important;
  transform: translateX(0) !important;
  z-index: 10;
}

.content-right .content-wrapper {
  padding: 0;
}

.file-manager-content-header {
  padding: 0.47rem 1rem;
  border-bottom: 1px solid #ebe9f1;
  background-color: #fff;
  border-top-right-radius: 0.357rem;
}

.file-manager-content-header .file-manager-toggler {
  cursor: pointer;
}

.file-manager-content-header input {
  border-color: transparent;
  box-shadow: none;
}

.file-manager-content-header .file-actions:not(.show) {
  display: none;
}

.view-container {
  display: flex;
  flex-wrap: wrap;
}

.view-container .file-manager-item {
  border: 1px solid #ebe9f1;
  margin-bottom: 1.6rem;
  box-shadow: none;
  margin-right: 1rem;
  transition: none;
  cursor: pointer;
}

.view-container .file-manager-item .card-body {
  padding: 1rem;
  padding-bottom: 0.5rem;
}

.view-container .file-manager-item .form-check-input:not(:checked) {
  background-color: transparent;
}

.view-container .file-manager-item .file-logo-wrapper {
  padding: 1rem;
  height: 7.5rem;
  background-color: #f8f8f8;
}

.view-container .file-manager-item .file-logo-wrapper .feather-folder {
  stroke: #babfc7;
}

.view-container .file-manager-item .dropdown-menu {
  transform: none;
}

.view-container .file-manager-item .dropdown-menu:before {
  display: none;
}

.view-container .file-manager-item .content-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: auto;
}

.view-container .file-manager-item .file-date {
  flex-grow: 1;
  margin-bottom: 0;
}

.view-container .file-manager-item.selected {
  border-color: #7367f0;
}

.view-container .file-name {
  width: calc(100% - 26rem);
  min-height: 1rem;
  font-weight: 600;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.view-container .files-section-title {
  width: 100%;
}

.view-container.list-view {
  flex-direction: column;
}

.view-container.list-view .files-section-title {
  display: none;
}

.view-container.list-view .files-header {
  display: flex;
  justify-content: space-between;
  margin-left: 7.2rem;
  margin-bottom: 1rem;
}

.view-container.list-view .files-header p {
  font-weight: 600;
}

.view-container.list-view .files-header .file-last-modified,
.view-container.list-view .files-header .file-item-size {
  margin-right: 3rem;
}

.view-container.list-view .file-manager-item {
  flex-direction: row;
  flex: 0 0 100%;
  align-items: center;
  max-width: 100%;
  margin-bottom: 0.75rem;
  margin-right: 0;
}

.view-container.list-view .file-manager-item .file-logo-wrapper {
  padding-right: 0;
  width: auto;
  height: auto;
  background-color: transparent !important;
}

.view-container.list-view .file-manager-item .file-logo-wrapper img {
  height: 1.5rem;
}

.view-container.list-view .file-manager-item .file-logo-wrapper .feather-folder,
.view-container.list-view .file-manager-item .file-logo-wrapper .feather-arrow-up {
  width: 19px;
  height: 18px;
}

.view-container.list-view .file-manager-item .file-logo-wrapper .dropdown {
  position: absolute;
  right: 1rem;
}

.view-container.list-view .file-manager-item .form-check {
  margin: 0 0.4rem 0 1.75rem;
}

.view-container.list-view .file-manager-item .file-accessed {
  display: none;
}

.view-container.list-view .file-manager-item .file-size {
  width: 5.71rem;
  flex-grow: 1;
  margin-bottom: 0;
}

.view-container.list-view .folder.level-up .file-logo-wrapper {
  margin-left: 3.5rem;
}

.view-container:not(.list-view) .files-header {
  display: none;
}

.view-container:not(.list-view) .file-manager-item .content-wrapper {
  margin-bottom: 0rem;
}

.view-container:not(.list-view) .file-manager-item .file-date {
  display: none;
}

.view-container:not(.list-view) .file-manager-item .file-size {
  color: #b9b9c3;
  font-size: 85%;
}

.view-container:not(.list-view) .file-manager-item .feather-folder {
  height: 32px;
  width: 35px;
}

.view-container:not(.list-view) .file-manager-item.folder.level-up {
  display: none !important;
}

.view-container:not(.list-view) .file-manager-item .form-check {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

@media screen and (max-width: 1200px) {
  .content-right {
    width: 100%;
  }
  .content-body {
    margin-left: 0 !important;
  }

  .sidebar-left .sidebar {
    z-index: 10;
  }
  .sidebar-left .sidebar .sidebar-file-manager {
    transform: translateX(-112%);
    transition: all 0.25s ease;
    position: absolute;
  }
}

@media (max-width: 767.98px) {
  .view-container .file-manager-item {
    flex: 47%;
  }
}

@media (max-width: 575.98px) {
  .file-manager-content-header {
    padding-left: 0.5rem;
  }
  .file-manager-content-header .file-actions .dropdown {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .view-container .file-manager-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .view-container.list-view .file-date,
  .view-container.list-view .file-last-modified,
  .view-container.list-view .file-size,
  .view-container.list-view .file-item-size {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .view-container .file-manager-item {
    flex: 0 0 23%;
    max-width: 25%;
  }
}

@media (max-width: 821px) {
  .view-container .file-manager-item {
    flex-grow: 1;
    max-width: 100%;
  }
}


.largerCheckbox{
  transform: scale(1.4);
}