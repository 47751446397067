.login_form_title {
  font-family: "Red Hat Display", sans-serif !important;
  font-weight: 700 !important;
  font-size: 2rem !important;
  font-style: normal !important;
  line-height: 69px;
  color: #363636 !important;
}

.verify_email_title {
  font-family: "Red Hat Display", sans-serif !important;
  font-weight: 700 !important;
  font-size: 1.5rem !important;
  font-style: normal !important;
  color: #363636 !important;
}