.survey_table_container {
    width: 100% !important;
    table-layout: auto !important;
}

.survey_table_header th {
    border: none;
    vertical-align: middle !important;
    white-space: pre-wrap;
    background-color: #f5f5f5;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #5B5B5B;
}
.survey_table_container tbody tr td {
    white-space: pre-wrap;
  }
