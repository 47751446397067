.filter_container {
  background-color: #f2f2f280;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
}

.dotted {
  border: 1px;
  width: 100%;
  margin: 0;
  padding: 0;
  border-color: #c5c5c5;
  border-style: none none dashed;
}

.bid_listing_card_container {
  border-radius: 0.5rem;
  overflow: hidden;
  height: 100%;
}

.bid_listing_card_header {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-bottom: none;
  background-color: #e7f1fd;
}

.pageSize {
  border: none !important;
  background-color: #f2f2f2 !important;
}

.sort_by {
  white-space: nowrap;
}

.active-tab {
  background-color: transparent !important;
  color: black !important;
  width: 100% !important;
}

.tab-button,
.tab-button:hover {
  background-color: #abaeb1;
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  /* margin: 0 5px; */
  color: white;
  cursor: pointer;
}

.tab-button-active,
.tab-button-active:hover {
  background-color: #1273eb;
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  /* margin: 0 5px; */
  color: white;
  cursor: pointer;
}

.side_detail_title {
  font-weight: 600;
  font-family: Montserrat;
  color: #283046;
}

.side_detail_text {
  font-weight: 400;
  font-family: Montserrat;
  color: #5C5F63;
}