.assessment_result_accordian {
  background-color: #f2f2f2;
  padding: 8px;
  border-radius: 8px;
}

.assessment_result_score {
  background-color: #1273eb;
  color: #fff;
  padding: 2px 8px;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
}

.assessment_result_total_score {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.assessment_result_analysis_card {
  font-family: "Montserrat", sans-serif !important;
}

.assessment_result_accordian_title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.assessment_result_action_plan_step {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.assessment_result_action_plan_description {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.back_to_survey_list {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.4px;
}

.assessment_analysis_buttons {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
}

.assessment_analysis_card_title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #363636;
}

.assessment_analysis_text {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;

  font-feature-settings: "liga" off;

  color: #363636;
}

.assessment_analysis_summary_text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;

  color: #5b5b5b;
}

.assessment_analysis_summary_text_pdf {
  width: 300px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;

  color: #5b5b5b;
}

.summary_saperator{
  width: 50px;
}


.survey-section-row:last-child {
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.user_detail_title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}
.user_detail_text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #0c0c0c;
}

.company_high_risk_badge{
  color: red;
  background-color: rgb(242, 223, 223);
  padding: 5px;
  border-radius: 10px;
}

.company_medium_risk_badge{
  color: rgb(137 137 59 / 58%);
  background-color: rgb(244 244 222 / 72%);
  padding: 5px;
  border-radius: 10px;
}

.company_low_risk_badge{
  color: #216c21;
  background-color: rgb(169 222 169 / 36%);
  padding: 5px;
  border-radius: 10px;
}

.recomm_summary_section {
  width: 40% !important;
}

.recomm_summary_separator {
  width: 10%;
}

@media (min-width: 768px) { 
  .recomm_summary_section_value {
    padding-left: 20%;
  }
 }

 .score_obtained_step_number {
  width: 20%;
 }

 .score_obtained_step_scored {
  width: 25%;
 }

 .score_obtained_separator {
  width: 5%;
}
