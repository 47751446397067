/* Chrome, Safari, Edge, Opera */
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  /* background-color: transparent; */
}

.big_text {
  display: flex;
  width: 100%;
  /* place-items: center; */
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8rem 0;
  text-align: center;
}

.big_text h1 {
  font-size: 10rem;
  transform: translatez(20rem);
  color: #32325d;
}

.color_blue {
  color: #375582;
  text-shadow: none;
  animation: visible 2s infinite;
}

.big_text p {
  letter-spacing: 1px;
  font-family: "Montserrat", sans-serif !important;
}

.small_texts {
  display: flex;
  justify-content: space-around;
}

.small_texts p {
  color: #888a85;
  animation: float 8s infinite;
  transform: translatey(4rem);
}

html {
  font-family: "Montserrat", sans-serif !important;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /* height: 100vh; */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100vh;
  font-family: "Montserrat", sans-serif !important ;
  font-size: 16px;
}

body {
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px;
}

.table td {
  padding: 0.4rem;
}

.main {
  display: flex;
  height: 100vh;
}
.sidecontainer {
  top: 0;
  margin-left: 250px;
  transition: margin-left 0.25s ease-in;
}
.sidecontainerclose {
  top: 0;
  margin-left: 60px;
  transition: margin-left 0.3s ease-in;
}
.post_error_loading {
  padding-top: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 787px) {
  .sidecontainer {
    margin-left: 0px;
    /* transition: margin-left 1s ease-in; */
  }
  .sidecontainerclose {
    top: 0;
    margin-left: 0px;
  }
}

.card-header {
  background-color: #fff;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  background-color: #1273eb !important;
}

.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}
.editor-class {
  background-color: #fff;
  padding: 1rem;
  border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
}

.otp-input::-webkit-outer-spin-button,
.otp-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-input[type="number"] {
  -moz-appearance: textfield;
}