.tab-list {
  height: 50px;
  font-family: "Montserrat", sans-serif !important;
  display: flex;
  list-style: none;
  font-size: 0.9rem;
  padding: 0;
  margin: 0;
}

.tab-list p {
  margin-right: 1rem;
  font-weight: 500;
}

.table_font b {
  font-weight: 600;
}
.table_data {
  font-weight: 600;
  border: none;
  background-color: #fff;
  color: black;
}
.tabs {
  height: 50%;
  margin: 0 0.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  position: relative;
  cursor: pointer;
  border-radius: 0.5rem;
  padding: 1rem;
}

.active-tabs {
  background: #1273eb;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-weight: 500;
}

.content {
  display: none;
  padding: 10px;
}

.active-content {
  display: block;
}
