.backdrop-text-center {
  width: 100%;
  position: relative;
  height: 100%;
}

.backdrop-spinner-border {
  display: block;
  position: fixed;
  top: calc(50% - (58px / 2));
  right: calc(50% - (58px / 2));
}

.backdrop-loader {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: rgba(116, 113, 113, 0.2);
}
